import React, { Component } from 'react';
import {BrowserRouter,Route,Switch,HashRouter} from 'react-router-dom';
import Menu from "./Menu";
import Employee_Dining from "./Employee_Dining";
import Conference_Centre from "./Conference_Centre";
import Work_Cafes from "./Work_Cafes";
import Sustainability from "./Sustainability";
import Healthy_Recipes from './Healthy_Recipes';
import Seasonal_Recipes from "./Seasonal_Recipes";
import Updates from "./Updates";
import "./Style.css";
import Footer from "./Footer";
import CounterMenu from "./CounterMenu";
import Tab2 from './Tab2';
import Tab3 from './Tab3';
import Tab4 from './Tab4';
import Tab5 from './Tab5';
import Allergens from './Allergens';





class App extends Component{  
  constructor(){
    super();
    this.state={
      tab2:[],
      tab3:[],
      tab4:[],
      tab5:[],
      Allergens:[],
      base_url:global.BaseUrl,
      
    }
  }

  componentDidMount(){

    fetch(`${this.state.base_url}/tab2`).then((resp)=>{
      resp.json().then((result)=>{
        //  console.warn(result);
        this.setState({ tab2: result});        
      })
    });

    fetch(`${this.state.base_url}/tab3`).then((resp)=>{
      resp.json().then((result)=>{
        //  console.warn(result);
        this.setState({ tab3: result});        
      })
    });

    fetch(`${this.state.base_url}/tab4`).then((resp)=>{
      resp.json().then((result)=>{
        //  console.warn(result);
        this.setState({ tab4: result});        
      })
    });

    fetch(`${this.state.base_url}/tab5`).then((resp)=>{
      resp.json().then((result)=>{
        //  console.warn(result);
        this.setState({ tab5: result});        
      })
    });


    
  }
  render(){
    const {tab2,tab3,tab4,tab5} = this.state;

    return(

      <div id="pagewrap" className="pagewrap">
      <div id="html-content" className="wrapper-content">
       <HashRouter>
        <Menu />
        <div className="page-container">
        <Switch>
          {/* <Route path="/"   component={Overview} /> */}
          <Route path="/" exact component={Employee_Dining} />
          <Route path="/catering-menu" exact component={Conference_Centre} />
          <Route path="/Work_Cafes" exact component={Work_Cafes} />
          <Route path="/Sustainability" exact component={Sustainability} />
          <Route path="/Healthy_Recipes" exact component={Healthy_Recipes} />
          <Route path="/Seasonal_Recipes" exact component={Seasonal_Recipes} />
          <Route path="/Allergens" exact component={Allergens} />
          <Route path="/Updates" exact component={Updates} />
          <Route path="/Footer" exact component={Footer} />
          <Route path="/CounterMenu/:id"  exact component={CounterMenu}/>
          {/* <Route path="/Lobby_Reception" component={Lobby_Reception} /> */}
          

             {
                tab2.map(page=>
                  <Route path={`/${page.sub_page}`} component={Tab2} />
                  )
              }

             {
                tab3.map(my_page=>
                  <Route path={`/${my_page.sub_page}`} component={Tab3} />
                  )
              }

             {
                tab4.map(new_my_page=>
                  <Route path={`/${new_my_page.sub_page}`} component={Tab4} />
                  )
              }

{
                tab5.map(new_my_page=>
                  <Route path={`/${new_my_page.sub_page}`} component={Tab5} />
                  )
              }
              


        </Switch>
        <Footer/>
        </div>
       </HashRouter>
       </div>
       </div>
    );
  }
}

export default App;
