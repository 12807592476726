import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <>
        <footer>
          <div className="" style={{ background: "#737070" }}>
            <div className="container">
              <div className="ft-widget-area footer_main_div">
                <div className="row">
                  <div class="col-md-3 col-sm-6">
                    <div className="ft-area1">
                      <div className="swin-widget swin-widget-about">
                        <div class="clerfix">
                          <div className="header-logo">
                            <img
                              src="./assets/images/logo/footer_logo.png"
                              alt="avenue"
                              className="logo-img "
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-9">
                    {/* <div className="row">
                                <div className="col-md-12">
                                    
                           <div class="col-md-6 col-sm-6">
                                <div className="ft-area1">
                                 <div className="swin-widget widget-about">
                                    <div className="title-widget footer_heading">Lobby Reception </div>
                                    <div class="about-contact-info clearfix">
                                        <div class="info-content phone-content">
                                        <div class="info-icon"><i class="fa-icon fa fa-phone"></i></div>
                                        <div class="info-text">
                                            <p>+632 7756 3691  / ext. 63691</p>
                                        </div>
                                        </div>
                                        <div class="info-content email-content">
                                        <div class="info-icon"><i class="fa-icon fa fa-envelope"></i></div>
                                        <div class="info-text">
                                            <p>lobbyreception.ph@jpmchase.com</p>
                                        </div>
                                        </div>
                                    </div>
                                 </div>
                               </div>
                           </div>
                           <div class="col-md-6 col-sm-6">
                                <div className="ft-area1">
                                 <div className="swin-widget widget-about">
                                    <div className="title-widget footer_heading">Client and Conference Center </div>
                                    <div class="about-contact-info clearfix">
                                        <div class="info-content phone-content">
                                        <div class="info-icon"><i class="fa-icon fa fa-phone"></i></div>
                                        <div class="info-text">
                                            <p>+632 7756 3649 / ext. 63649</p>
                                        </div>
                                        </div>
                                        <div class="info-content email-content">
                                        <div class="info-icon"><i class="fa-icon fa fa-envelope"></i></div>
                                        <div class="info-text">
                                            <p>clientandconferencecenter.ph@jpmchase.com</p>
                                            
                                        </div>
                                        </div>
                                    </div>
                                 </div>
                               </div>
                           </div>

                                </div>
                            </div>
                            <hr/> */}

                    <div className="row">
                      <div className="col-md-12">
                        <div class="col-md-6 col-sm-6">
                          <div className="ft-area1">
                            <div className="swin-widget widget-about">
                              <div className="title-widget footer_heading">
                                Employee Dining
                              </div>
                              <div class="about-contact-info clearfix">
                                <div class="info-content phone-content">
                                  <div class="info-icon">
                                    <i class="fa-icon fa fa-phone"></i>
                                  </div>
                                  <div class="info-text">
                                    <p>+65 86183651</p>
                                  </div>
                                </div>
                                <div class="info-content email-content">
                                  <div class="info-icon mt-10">
                                    <i class="fa-icon fa fa-envelope"></i>
                                  </div>
                                  <div class="info-text">
                                    <p className="email">
                                      alan.chua@jpmchase.com
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-6 col-sm-6">
                          <div className="ft-area1">
                            <div className="swin-widget widget-about">
                              <div className="title-widget footer_heading">
                                Pantry Services
                              </div>
                              <div class="about-contact-info clearfix">
                                <div class="info-content phone-content">
                                  <div class="info-icon ">
                                    <i class="fa-icon fa fa-phone"></i>
                                  </div>
                                  <div class="info-text">
                                    <p>+65 86183651</p>
                                  </div>
                                </div>
                                <div class="info-content email-content">
                                  <div class="info-icon mt-10">
                                    <i class="fa-icon fa fa-envelope"></i>
                                  </div>
                                  <div class="info-text">
                                    <p className="email">
                                      alan.chua@jpmchase.com{" "}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }
}

export default Footer;
